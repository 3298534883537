import React, { useState } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Paper,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Link,
  BottomNavigation,
  BottomNavigationAction,
  IconButton,
  Avatar
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import footerImage from './footer.png';
import MenuIcon from "@mui/icons-material/Menu";
import LockIcon from '@mui/icons-material/Lock';
import SmsIcon from '@mui/icons-material/Sms';
import TimerIcon from '@mui/icons-material/Timer';
import PlanIcon from '@mui/icons-material/Event';
import passenger from './passenger.png'
import grocery from './grocery.png'
import clock from './clock.png'
import luggage from './luggage.png'
import pinky from './pinky-promise.png'
import planb from './planb.png'
import crowdfunding from './crowdfunding.png'
import smartphone from './smartphone.png'
import info from './info.jpeg'
import { useTheme, useMediaQuery } from '@mui/material';

function ResponsiveImage({ src, alt }) {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery('(max-width:400px)'); // Extra small devices
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')); // Small devices
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  let imageSize = {
    width: '600px',
    height: '700px'
  };

  if (isExtraSmall) {
    imageSize = {
      width: '310px',
      height: '420px',
    };
  } else if (isSmall) {
    imageSize = {
      width: '400px',
      height: '500px',
    };
  } else if (isMedium) {
    imageSize = {
      width: '550px',
      height: '650px',
    };
  }

  return <img src={src} alt={alt} style={imageSize} />;
}

function AboutUs() {
  const [navValue, setNavValue] = useState(0); // Set to default 0 for 'Home'
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
    if (newValue === 0) {
      navigate("/");
    } else if (newValue === 1) {
      navigate("/my-rides");
    } else if (newValue === 2) {
      navigate("/create-ride");
    } else if (newValue === 3) {
      navigate("/profile");
    } else if (newValue === 4) {
      navigate("/packets-selection");
    }
  };

  return (
    <div style={{ backgroundColor: "#F8B400", minHeight: "100vh", display: 'flex', flexDirection: 'column' }}>
      {/* AppBar */}
      <AppBar position="fixed" sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(10px)", paddingBottom: 1, paddingTop: 0.5 }}>
        <Toolbar>
          <Hidden smUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
              sx={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h6" sx={{ color: "black", fontFamily: "Outfit", fontWeight: "bold", flexGrow: 1 }}>
            Upsy.mk
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Drawer anchor="left" sx={{ backdropFilter:"blur(10px)" }} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <List>
          <ListItem button onClick={() => { navigate("/about-us"); setDrawerOpen(false); }}>
            <ListItemText primary="About Us" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/terms-and-conditions"); setDrawerOpen(false); }}>
            <ListItemText primary="Terms and Conditions" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/privacy-policy"); setDrawerOpen(false); }}>
            <ListItemText primary="Privacy Policy" />
          </ListItem>
        </List>
      </Drawer>

      {/* Side Navbar */}
      <Container style={{ marginTop: 64, display: 'flex', padding: 0, justifyContent: 'center' }}>
        <Hidden smDown>
          <Box sx={{ width: 200, marginRight: 0.5, position: "fixed", marginRight: 130 }}>
            <Box sx={{ textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" style={{ fontFamily: 'Outfit', fontWeight: 'bold' }}>
                Upsy.
              </Typography>
              <Typography variant="body2" style={{ fontFamily: 'Montserrat' }}>
                Каде денес?
              </Typography>
            </Box>
            <List component="nav" style={{ position: 'relative', top: 0 }}>
              <ListItem button selected={navValue === 0} onClick={() => handleNavChange(null, 0)}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Дома" />
              </ListItem>
              <ListItem button selected={navValue === 1} onClick={() => handleNavChange(null, 1)}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold', fontSize:'15px'}} primary="Мои Превози" />
              </ListItem>
              <ListItem button selected={navValue === 2} onClick={() => handleNavChange(null, 2)}>
                <ListItemIcon>
                  <PlaylistAddIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Нов Превоз" />
              </ListItem>
              <ListItem button selected={navValue === 3} onClick={() => handleNavChange(null, 3)}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Профил" />
              </ListItem>
              <ListItem button selected={navValue === 4} onClick={() => handleNavChange(null, 4)}>
                <ListItemIcon>
                  <AddToPhotosIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Пакети" />
              </ListItem>
            </List>
          </Box>
        </Hidden>

        {/* Main Content */}
        <Container maxWidth="md" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 35, paddingBottom: 50 }}>
        <Paper sx={{ p: 3, borderRadius: 3, border: '1px solid #ddd', maxWidth: 600, margin: '0 auto', '@media (max-width: 600px)': { margin: 2 } }}>
            <Typography variant="h4" component="h1" gutterBottom style={{ color: "black", textAlign: 'center' }} sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              За нас
            </Typography>
            <Typography variant="body1" fontFamily="Montserrat">
            <b>За Нас</b><br />
              Добредојдовте на Upsy.mk, вашата платформа за барање и понуда на превози. Со нашата платформа, лесно можете да најдете или понудите превоз до секој град во земјата. Се стремиме да ги олесниме вашите патувања и да ви обезбедиме сигурно и пријатно превозно искуство.
              <br /><br /><b>Како работи Upsy.mk</b><br></br>
            </Typography>
            <ResponsiveImage src={info} alt="Plan B" />

            <Typography variant="body1" fontFamily="Montserrat">
            <br /><br /><b>Бонтон на Upsy.mk</b><br />
            </Typography>
            <List sx={{ width: '100%' }}>
            <ListItem>
              <ListItemIcon>
                <img src={pinky} alt="Pinky Promise" style={{ width: '60px', height: '60px',paddingRight: '10px'}} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} secondaryTypographyProps={{fontFamily:'Montserrat', fontWeight:'bold'}}  primary={<b>ЗАДРЖЕТЕ ГО ВАШИОТ ДОГОВОР</b>} secondary="Ако склучите договор, обидете се да се држите до него. Ова важи и за возачите и за патниците." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={smartphone} alt="Smartphone" style={{ width: '60px', height: '60px',paddingRight: '10px' }}/>
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} secondaryTypographyProps={{fontFamily:'Montserrat', fontWeight:'bold'}} primary={<b>ИСПРАТЕТЕ ПРИЈАТНА СМС ПОРАКА</b>} secondary="Ако оставите добар прв впечаток, имате поголеми шанси да најдете придружници или возач." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={crowdfunding} alt="Expense Sharing" style={{ width: '60px', height: '60px',paddingRight: '10px' }}/>
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} secondaryTypographyProps={{fontFamily:'Montserrat', fontWeight:'bold'}}  primary={<b>СПОДЕЛУВАЊЕ НА ТРОШОЦИ</b>} secondary="Договорете се за поделба на трошоците однапред и држете се до договорот." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={clock} alt="Be Punctual" style={{ width: '60px', height: '60px',paddingRight: '10px' }}/>
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} secondaryTypographyProps={{fontFamily:'Montserrat', fontWeight:'bold'}} primary={<b>БИДЕТЕ ТОЧНИ</b>} secondary="Договорете за точното време и место на поаѓање. Како возач, поставете правила за доцните лица за да знаат кога ќе им се јавите и колку долго ќе чекате. Препорачуваме околу 5 минути." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={planb} alt="Plan B" style={{ width: '60px', height: '60px',paddingRight: '10px' }}/>
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} secondaryTypographyProps={{fontFamily:'Montserrat', fontWeight:'bold'}} primary={<b>ПЛАН Б</b>} secondary="Не заборавајте резервен план за случаи кога возачот не може да патува поради неочекувани компликации. Препорачуваме да имате алтернативен провајдер или како последна опција, да планирате да користите јавен превоз." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={passenger} alt="Plan B" style={{ width: '60px', height: '60px',paddingRight: '10px' }}/>
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} secondaryTypographyProps={{fontFamily:'Montserrat', fontWeight:'bold'}} primary={<b>ВОЗЕТЕ ОДГОВОРНО</b>} secondary="Како возач, вие сте одговорни за вашите патници, затоа почитувајте ги сообраќајните правила и не возете под дејство на алкохол или други забранети супстанции. Ако сте патник и забележите дека возачот е под дејство на алкохол, љубезно замолете го да застане на најблиското соодветно место." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={grocery} alt="Plan B" style={{ width: '60px', height: '60px',paddingRight: '10px' }}/>
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} secondaryTypographyProps={{fontFamily:'Montserrat', fontWeight:'bold'}} primary={<b>ОБВРСКИТЕ МОЖЕ ДА ПОЧЕКААТ</b>} secondary="Ако е можно, извршете ги обврските во друго време кога нема да патувате со патници. Ако не можете да ги избегнете обврските, љубезно прашајте ги вашите сопатници дали тоа е прифатливо за нив." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={luggage} alt="Plan B" style={{ width: '60px', height: '60px',paddingRight: '10px' }}/>
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} secondaryTypographyProps={{fontFamily:'Montserrat', fontWeight:'bold'}} primary={<b>БАГАЖОТ НЕ Е ПРЕДВИДЕН</b>} secondary="Ако сте патник и имате поголеми парчиња багаж од вообичаено, разговарајте за ова однапред со возачот и проверете дали има доволно простор." />
            </ListItem>
          </List>
          
          <Typography variant="body1" fontFamily="Montserrat">
            
            Непочитувањето на бонтон и Правила и Услови може да резултира со суспензија на вашата корисничка сметка на Upsy.mk.              
            </Typography>
          </Paper>
        </Container>
      </Container>

      {/* Bottom Navigation */}
      <Hidden smUp>
        <Box sx={{ width: '100%', position: 'fixed', bottom: 0, backgroundColor: '#fff' }}>
          <BottomNavigation
            value={navValue}
            onChange={handleNavChange}
            showLabels
          >
            <BottomNavigationAction label="Дома" icon={<HomeIcon />} />
            <BottomNavigationAction label="Мои Превози" icon={<DirectionsCarIcon />} />
            <BottomNavigationAction label="Нов Превоз" icon={<PlaylistAddIcon />} />
            <BottomNavigationAction label="Профил" icon={<AccountCircleIcon />} />
            <BottomNavigationAction label="Пакети" icon={<AddToPhotosIcon />} />
          </BottomNavigation>
        </Box>
      </Hidden>

      {/* Footer */}
      <Hidden smDown>
      <Box
  sx={{
    position: 'relative',
    width: '100%',
    height: 'auto', // Adjust height to fit content, not fixed height
    mt: 'auto', // Pushes the footer down if there is content above it
    padding: 0, // Ensure no padding
    margin: 0, // Ensure no margin
    display: 'flex', // Flexbox to help align the content
    justifyContent: 'center' // Centers content in the middle horizontally
  }}
>
  <img src={footerImage} alt="Footer" style={{ width: '100%', position: 'relative', bottom: 0 }} />
  <Box
    sx={{
      position: 'absolute',
      bottom: '20px',
      left: '10px',
      display: 'flex',
      gap: '20px',
    }}
  >
    <Link href="/about-us" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>За нас</Link>
    <Link href="/terms-and-conditions" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>Правила и Услови</Link>
    <Link href="/privacy-policy" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>Политика на Приватност</Link>
  </Box>
</Box>
      </Hidden>
    </div>
  );
}

export default AboutUs;
