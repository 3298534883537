// src/SearchRides.js
import React, { useState, useEffect } from "react";
import { db } from "./firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import {
  Container,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

function SearchRides() {
  const [search, setSearch] = useState({ fromCity: "", toCity: "", date: "" });
  const [rides, setRides] = useState([]);

  const handleChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const handleSearch = async () => {
    const ridesRef = collection(db, "rides");
    const q = query(
      ridesRef,
      where("fromCity", "==", search.fromCity),
      where("toCity", "==", search.toCity),
      where("date", "==", search.date)
    );
    const querySnapshot = await getDocs(q);
    setRides(querySnapshot.docs.map((doc) => doc.data()));
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Upsy.
      </Typography>
      <TextField
        label="From"
        variant="outlined"
        fullWidth
        margin="normal"
        name="fromCity"
        value={search.fromCity}
        onChange={handleChange}
      />
      <TextField
        label="To"
        variant="outlined"
        fullWidth
        margin="normal"
        name="toCity"
        value={search.toCity}
        onChange={handleChange}
      />
      <TextField
        label="Date"
        type="date"
        variant="outlined"
        fullWidth
        margin="normal"
        name="date"
        value={search.date}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
      />
      <Button variant="contained" fullWidth onClick={handleSearch}>
        Search
      </Button>
      <List>
        {rides.map((ride, index) => (
          <ListItem button key={index}>
            <ListItemText
              primary={`${ride.fromCity} > ${ride.toCity}`}
              secondary={`Time: ${ride.time}, Price: ${ride.price}`}
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default SearchRides;
