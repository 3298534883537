import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

const Checkout = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const selectedPackage = state?.selectedPackage;

  const [form, setForm] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    cardNumber: '',
    expiryDate: '',
    cvv: ''
  });

  const [navValue, setNavValue] = useState(4); // Default value for the navigation

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    // Handle the form submission, e.g., send the data to the server
    console.log("Form submitted:", form);
  };

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
    if (newValue === 0) {
      navigate("/");
    } else if (newValue === 1) {
      navigate("/my-rides");
    } else if (newValue === 2) {
      navigate("/create-ride");
    } else if (newValue === 3) {
      navigate("/profile");
    } else if (newValue === 4) {
      navigate("/packets-selection");
    }
  };

  return (
    <div style={{ backgroundColor: "#F8B400", minHeight: "100vh", paddingBottom: 56, paddingTop: 4 }}>
      <AppBar position="fixed" sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(10px)", paddingBottom: 1, paddingTop: 0.5 }}>
        <Toolbar>
          <Typography variant="h6" sx={{ color: "black", fontFamily: "Outfit", fontWeight: "bold", flexGrow: 1 }}>
            Upsy.mk
          </Typography>
        </Toolbar>
      </AppBar>
      <Container style={{ marginTop: 64, display: 'flex', padding: 0, justifyContent: 'center' }}>
        <Hidden smDown>
          <Box sx={{ width: 200, marginRight: 0.5 }}>
            <Box sx={{ textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" style={{ fontFamily: 'Outfit', fontWeight: 'bold' }}>
                Upsy.
              </Typography>
              <Typography variant="body2" style={{ fontFamily: 'Montserrat' }}>
                Каде денес?
              </Typography>
            </Box>
            <List component="nav" style={{ position: 'relative', top: 0 }}>
              <ListItem button onClick={() => navigate("/")}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Дома" />
              </ListItem>
              <ListItem button onClick={() => navigate("/my-rides")}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primary="Мои Превози" />
              </ListItem>
              <ListItem button onClick={() => navigate("/create-ride")}>
                <ListItemIcon>
                  <PlaylistAddIcon />
                </ListItemIcon>
                <ListItemText primary="Нов Превоз" />
              </ListItem>
              <ListItem button onClick={() => navigate("/profile")}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Профил" />
              </ListItem>
              <ListItem button onClick={() => navigate("/packets-selection")}>
                <ListItemIcon>
                  <AddToPhotosIcon />
                </ListItemIcon>
                <ListItemText primary="Пакети" />
              </ListItem>
            </List>
          </Box>
        </Hidden>
        <Container maxWidth="md" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 35 }}>
          <Paper sx={{ p: 3, borderRadius: 5, border: '1px solid #ddd', maxWidth: 600, marginTop:1, marginBottom:4, ml:2,mr:2 }}>
            <Typography variant="h4" component="h1" gutterBottom style={{ color: "black", textAlign: 'center' }} sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              Checkout
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom style={{ color: "black", textAlign: 'center' }} sx={{ fontFamily: 'Montserrat' }}>
              Одбран пакет: {selectedPackage?.title}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Име и Презиме"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Адреса"
                  name="address"
                  value={form.address}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Град"
                  name="city"
                  value={form.city}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Држава"
                  name="state"
                  value={form.state}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Зип Код"
                  name="zip"
                  value={form.zip}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Број на картичка"
                  name="cardNumber"
                  value={form.cardNumber}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Датум на истекување"
                  name="expiryDate"
                  value={form.expiryDate}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="CVV"
                  name="cvv"
                  value={form.cvv}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSubmit}
                  style={{ backgroundColor: "#F8B400", color: "black", border:"1px solid black", fontFamily:"Montserrat", fontWeight:"bold" }}
                  sx={{ maxWidth: 600 }}
                >
                  Заврши нарачка.
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Container>
      <Hidden smUp>
        <Box sx={{ width: '100%', position: 'fixed', bottom: 0, backgroundColor: '#fff' }}>
          <BottomNavigation
            value={navValue}
            onChange={handleNavChange}
            showLabels
          >
            <BottomNavigationAction label="Дома" icon={<HomeIcon />} />
            <BottomNavigationAction label="Мои Превози" icon={<DirectionsCarIcon />} />
            <BottomNavigationAction label="Нов Превоз" icon={<PlaylistAddIcon />} />
            <BottomNavigationAction label="Профил" icon={<AccountCircleIcon />} />
          </BottomNavigation>
        </Box>
      </Hidden>
    </div>
  );
};

export default Checkout;
