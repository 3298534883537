// src/CitySelection.js
import React, { useState, useEffect } from "react";
import { db } from "./firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LocationCityIcon from "@mui/icons-material/LocationCity";

function CitySelection() {
  const [cities, setCities] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchCities = async () => {
      const querySnapshot = await getDocs(collection(db, "cities"));
      const citiesList = querySnapshot.docs.map((doc) => doc.data().name);
      setCities(citiesList);
    };

    fetchCities();
  }, []);

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Select a city</Typography>
        </Toolbar>
      </AppBar>
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        style={{ margin: "20px 0" }}
      />
      <List>
        {cities
          .filter((city) =>
            city.toLowerCase().includes(search.toLowerCase())
          )
          .map((city, index) => (
            <ListItem button key={index}>
              <ListItemIcon>
                <LocationCityIcon />
              </ListItemIcon>
              <ListItemText primary={city} />
            </ListItem>
          ))}
      </List>
    </div>
  );
}

export default CitySelection;
