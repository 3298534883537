// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, setPersistence, browserLocalPersistence, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA-0iHvWAtXmr68YE91OcQiGsYHez-IH24",
  authDomain: "prevozi-mk.firebaseapp.com",
  projectId: "prevozi-mk",
  storageBucket: "prevozi-mk.appspot.com",
  messagingSenderId: "467000119099",
  appId: "1:467000119099:web:7a13e3141da70e9eaf311e",
  measurementId: "G-2HPSC2LXD7"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Persistence set to local.");
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

export { db, auth, storage, googleProvider };
