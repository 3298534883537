import React, { useState } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Paper,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Link,
  BottomNavigation,
  BottomNavigationAction,
  IconButton
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import footerImage from './footer.png';
import MenuIcon from "@mui/icons-material/Menu";

function TermsAndConditions() {
  const [navValue, setNavValue] = useState(0); // Set to default 0 for 'Home'
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
    if (newValue === 0) {
      navigate("/");
    } else if (newValue === 1) {
      navigate("/my-rides");
    } else if (newValue === 2) {
      navigate("/create-ride");
    } else if (newValue === 3) {
      navigate("/profile");
    } else if (newValue === 4) {
      navigate("/packets-selection");
    }
  };

  return (
    <div style={{ backgroundColor: "#F8B400", display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* AppBar */}
      <AppBar position="fixed" sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(10px)", paddingBottom: 1, paddingTop: 0.5 }}>
        <Toolbar>
          <Hidden smUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
              sx={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h6" sx={{ color: "black", fontFamily: "Outfit", fontWeight: "bold", flexGrow: 1 }}>
            Upsy.mk
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Drawer anchor="left" sx={{ backdropFilter:"blur(10px)" }} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <List>
          <ListItem button onClick={() => { navigate("/about-us"); setDrawerOpen(false); }}>
            <ListItemText primary="About Us" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/terms-and-conditions"); setDrawerOpen(false); }}>
            <ListItemText primary="Terms and Conditions" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/privacy-policy"); setDrawerOpen(false); }}>
            <ListItemText primary="Privacy Policy" />
          </ListItem>
        </List>
      </Drawer>

      {/* Side Navbar */}
      <Container style={{ marginTop: 64, display: 'flex', padding: 0, justifyContent: 'center', flex: 1 }}>
      <Hidden smDown>
          <Box sx={{ width: 200, marginRight: 0.5, position: "fixed", marginRight: 130 }}>
            <Box sx={{ textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" style={{ fontFamily: 'Outfit', fontWeight: 'bold' }}>
                Upsy.
              </Typography>
              <Typography variant="body2" style={{ fontFamily: 'Montserrat' }}>
                Каде денес?
              </Typography>
            </Box>
            <List component="nav" style={{ position: 'relative', top: 0 }}>
              <ListItem button selected={navValue === 0} onClick={() => handleNavChange(null, 0)}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Дома" />
              </ListItem>
              <ListItem button selected={navValue === 1} onClick={() => handleNavChange(null, 1)}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold', fontSize:'15px'}} primary="Мои Превози" />
              </ListItem>
              <ListItem button selected={navValue === 2} onClick={() => handleNavChange(null, 2)}>
                <ListItemIcon>
                  <PlaylistAddIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Нов Превоз" />
              </ListItem>
              <ListItem button selected={navValue === 3} onClick={() => handleNavChange(null, 3)}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Профил" />
              </ListItem>
              <ListItem button selected={navValue === 4} onClick={() => handleNavChange(null, 4)}>
                <ListItemIcon>
                  <AddToPhotosIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Пакети" />
              </ListItem>
            </List>
          </Box>
        </Hidden>

        {/* Main Content */}
        <Container maxWidth="md" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 35, paddingBottom: 50 }}>
        <Paper sx={{ p: 3, borderRadius: 3, border: '1px solid #ddd', maxWidth: 600, margin: '0 auto', '@media (max-width: 600px)': { margin: 2 } }}>
            <Typography variant="h4" component="h1" gutterBottom style={{ color: "black", textAlign: 'center' }} sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              Правила и Услови
            </Typography>
            <Typography variant="body1" fontFamily="Montserrat">

  <b>Дозволена употреба на платформата</b><br />
  Се согласувате да ги користите услугите и платформата исклучиво за некомерцијални врски со луѓе кои сакаат да патуваат со вас и исклучиво за целите на споделување на трошоците за превоз.<br /><br />
  
  <b>1. Предмет</b><br />
  Платформата на Коки Шпед ДООЕЛ (во натамошниот текст „Upsy.mk“) е достапна на веб-страницата Upsy.mk или во форма на мобилна апликација (во натамошниот текст „платформа“).<br />
  Ве молиме внимателно прочитајте ги овие услови. Вие признавате и разбирате дека Upsy.mk не е страна на каков било договор, договор или договорен однос од кој било вид склучен помеѓу членовите на неговата платформа.<br />
  Со кликнување на копчето „Најави се со сметка на Google“ или „Регистрирај се со сметка на Google“ или „Регистрирај се со е-пошта“, потврдувате дека сте ги прочитале и се согласувате со овие одредби и услови.<br /><br />

  <b>2. Регистрација на платформата и креирање сметка</b><br />
  <b>2.1. Услови за регистрација на платформата</b><br />
  Платформата е наменета за лица на возраст од 18 и повеќе години. Регистрацијата на платформата е строго забранета за малолетници. Со регистрирање и користење на платформата гарантирате дека имате над 18 години.<br /><br />

  <b>2.2. Создавање сметка</b><br />
  Платформата им овозможува на членовите да креираат и прегледуваат објави. За да се рекламирате, мора да се регистрирате на нашата платформа користејќи го формуларот за регистрација или сметка на Google. Без оглед на користениот метод за регистрација, со креирање на сметка потврдувате дека ќе користите точни и вистинити информации и ќе направите промени на вашиот профил или ќе го известите Upsy.mk за какви било промени...<br /><br />

  <b>3. Верификација</b><br />
  За подобра транспарентност, зголемена доверба, спречување или откривање на злоупотреба, Upsy.mk може да воспостави систем за проверка на одредени информации што ќе ги доставите со вашиот профил.<br />
  Вие разбирате и се согласувате дека секое поврзување со платформата или услуга за проверка на информации или сличен термин значи само дека членот успешно го поминал процесот на верификација на платформата за да ви обезбеди повеќе информации за членот со кој размислувате да патувате. Upsy.mk не може да гарантира за вистинитоста, веродостојноста и валидноста на информациите кои се предмет на процесот на верификација.<br /><br />

  <b>4. Користење на услуги</b><br />
  <b>4.1. Објавување огласи</b><br />
  Како член кој ги исполнува сите услови наведени подолу, можете да креирате и објавувате реклами на платформата со внесување детали за патување што ги планирате (датум/време, влезни точки, време на пристигнување, број на достапни места, опис, износ на придонес за трошоци, итн.).<br />
  Вие сте овластени да објавувате оглас доколку ги исполнувате следниве услови:<br />
  (а) Имате важечка возачка дозвола;<br />
  (б) Нудите возења за патување со сопствено возило или со возило за кое имате дозвола од сопственикот и во сите случаи кога сте овластени да обезбедувате превоз;<br />
  (в) Вие сте и ќе останете главен возач на возилото;<br />
  (г) Возилото има важечко осигурување од трето лице;<br />
  (д) Немате контраиндикации или здравствени неспособности за возење;<br />
  (ѓ) Возилото што имате намера да го користите е автомобил со четири тркала и максимум седум седишта (важи за некомерцијален превоз);<br />
  (е) Немате намера да објавувате нова реклама на платформата за истото патување;<br />
  (ж) Не нудите повеќе достапни места отколку што се достапни во вашето возило;<br />
  (з) Сите седишта имаат сигурносни појаси, дури и ако на возилото му е дозволено да има седишта без сигурносни појаси;<br />
  (ѕ) Го користите возилото во добра возна состојба, во согласност со законските одредби.<br /><br />

  <b>4.2 Ограничување</b><br />
  Upsy.mk го задржува правото да ја укине вашата сметка, да го ограничи вашиот пристап до услугите или да ги откаже општите услови во секое време без претходна најава.<br /><br />

  <b>5. Придонес кон трошоците</b><br />
  Придонесот кон трошоците го одредувате како возач, на ваша одговорност. Секоја експлоатација преку користење на нашата платформа е строго забранета. Се согласувате да го ограничите придонесот на трошоците што ги барате од патниците на реалните трошоци на вашето патување.<br /><br />

  <b>6. Некомерцијални друштва за природа и транспорт</b><br />
  <b>6.1 Некомерцијална и неделовна цел на услугите и платформата</b><br />
  Се согласувате да ги користите услугите и платформата исклучиво за некомерцијални интеракции со луѓе кои сакаат да патуваат со вас и исклучиво за целите на споделување на трошоците за превоз. <br /><br />

  <b>6.2 Додавање возења</b><br />
  Додавањето возења во некои случаи се наплатува и се наплаќа според пакетите...<br /><br />

  <b>7. Однесување на корисниците и членовите на платформата</b><br />
  <b>7.1. Обврска на сите корисници на платформата</b><br />
  Вие разбирате дека сте одговорни за усогласување со сите закони и други прописи кои се однесуваат на вас кога ја користите платформата...<br /><br />

  <b>7.2. Обврски на возачот</b><br />
  Кога ја користите платформата како двигател, се обврзувате да:<br />
  (а) да се усогласат со сите закони, прописи и шифри кои се применуваат за возење и возила, особено за осигурување од граѓанска одговорност што е во сила за време на возењето, и да поседува важечка возачка дозвола;<br />
  (б) проверете дали вашето осигурување покрива здружување со автомобил и дали вашите патници се сметаат за трети лица во вашето возило и затоа се покриени со вашето осигурување во текот на целото возење, дури и прекугранично;<br /><br />

  <b>7.3. Обврски на патниците</b><br />
  Кога ја користите платформата како патник, се согласувате на:<br />
  (а) да се однесуваат соодветно за време на возењето и да не ја нарушуваат концентрацијата на возачот, мирот или спокојството на другите патници;<br />
  (б) почитувајте го возилото на возачот и неговата чистота;<br /><br />

  <b>8. Привремено суспендирање на сметки, ограничување на пристапот и прекинување</b><br />
  Можете да го раскинете вашиот договорен однос со Upsy.mk во секое време, без трошоци и без да наведете причина...<br /><br />

  <b>9. Лични податоци</b><br />
  Upsy.mk ќе собира и обработува одредени лични податоци како дел од вашата употреба на платформата...<br /><br />

  <b>10. Интелектуална сопственост</b><br />
  <b>10.1 Содржина обезбедена од Upsy.mk</b><br />
  Upsy.mk е единствениот носител на сите права на интелектуална сопственост поврзани со услугата, платформата, нејзината содржина...<br /><br />

  <b>10.2 Содржина што сте ја објавиле на платформата</b><br />
  За да овозможите обезбедување на услуги и во согласност со целта на платформата, му давате лиценца на Upsy.mk да ги користи содржините и податоците што ги давате како дел од вашето користење на услугите...<br /><br />

  <b>11. Улога на Upsy.mk</b><br />
  Платформата претставува онлајн мрежна платформа на која членовите можат да креираат и објавуваат реклами за возења за цели на патување...<br /><br />

  <b>12. Работење, достапност и функционалност на платформата</b><br />
  Upsy.mk ќе се обиде да го одржи пристапот до платформата 7 дена во неделата и 24 часа на ден во најголема можна мера...<br /><br />

  <b>13. Промени на Условите</b><br />
  Овие услови и референтни документи го сочинуваат целиот договор помеѓу вас и Upsy.mk во врска со вашето користење на услугите...<br /><br />

  <b>14. Правни известувања</b><br />
  Платформата е објавена и управувана од компанијата Коки Шпед ДООЕЛ, Шишка бр. 5, Кавадарци, Северна Македонија.<br />
  За какви било дополнителни прашања во врска со условите или платформата, ве молиме контактирајте не на info@upsy.mk.<br /><br />
  Последен пат овие услови беа ажурирани на 11.09.2024 година.<br /><br />
</Typography>

          </Paper>
        </Container>
      </Container>

      {/* Bottom Navigation */}
      <Hidden smUp>
        <Box sx={{ width: '100%', position: 'fixed', bottom: 0, backgroundColor: '#fff' }}>
          <BottomNavigation
            value={navValue}
            onChange={handleNavChange}
            showLabels
          >
            <BottomNavigationAction label="Дома" icon={<HomeIcon />} />
            <BottomNavigationAction label="Мои Превози" icon={<DirectionsCarIcon />} />
            <BottomNavigationAction label="Нов Превоз" icon={<PlaylistAddIcon />} />
            <BottomNavigationAction label="Профил" icon={<AccountCircleIcon />} />
            <BottomNavigationAction label="Пакети" icon={<AddToPhotosIcon />} />
          </BottomNavigation>
        </Box>
      </Hidden>

      {/* Footer */}
      <Hidden smDown>
      <Box
  sx={{
    position: 'relative',
    width: '100%',
    height: 'auto', // Adjust height to fit content, not fixed height
    mt: 'auto', // Pushes the footer down if there is content above it
    padding: 0, // Ensure no padding
    margin: 0, // Ensure no margin
    display: 'flex', // Flexbox to help align the content
    justifyContent: 'center' // Centers content in the middle horizontally
  }}
>
  <img src={footerImage} alt="Footer" style={{ width: '100%', position: 'relative', bottom: 0 }} />
  <Box
    sx={{
      position: 'absolute',
      bottom: '20px',
      left: '10px',
      display: 'flex',
      gap: '20px',
    }}
  >
    <Link href="/about-us" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>За нас</Link>
    <Link href="/terms-and-conditions" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>Правила и Услови</Link>
    <Link href="/privacy-policy" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>Политика на Приватност</Link>
  </Box>
</Box>
      </Hidden>
    </div>
  );
}

export default TermsAndConditions;
