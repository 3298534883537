import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { Avatar } from "@mui/material";
import logo from './logo.png'; // Ensure the path to logo.png is correct

const ProtectedRoute = ({ children }) => {
  const auth = getAuth();
  const [user, loading] = useAuthState(auth);
  const location = useLocation();

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Avatar src={logo} alt="Loading" style={{ width: 100, height: 100 }} />
      </div>
    );
  }

  // Allow access to main-screen route even if the user is not logged in
  if (!user && location.pathname !== '/main-screen') {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
