import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Link,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  AppBar,
  Toolbar,
  Snackbar,
  Alert,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { googleProvider, db } from "./firebaseConfig";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import ListIcon from "@mui/icons-material/List";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { doc, getDoc,setDoc } from "firebase/firestore";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [navValue, setNavValue] = useState(3);
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/");
    } catch (error) {
      let errorMessage = "";
      if (error.code === "auth/invalid-email") {
        errorMessage = "Невалидна емаил адреса.";
      } else if (error.code === "auth/user-not-found") {
        errorMessage = "Невалидна емаил адреса или корисникот не постои.";
      } else if (error.code === "auth/wrong-password") {
        errorMessage = "Погрешна лозинка. Обидете се повторно.";
      } else {
        errorMessage = "Грешка при најава. Обидете се повторно.";
      }
      setError(errorMessage);
      setSnackbarOpen(true);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
  
      // Split the displayName into name and surname
      const fullName = user.displayName || "";
      const nameParts = fullName.split(" ");
      const firstName = nameParts[0] || ""; // Take the first part as the name
      const lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : ""; // Combine the remaining parts as surname
  
      // Check if the user already has a document in Firestore
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) {
        // If the user document doesn't exist, create a new one with split name and surname
        const newUser = {
          name: firstName,
          surname: lastName,
          email: user.email || "",
          phoneNumber: "", // Default empty value or request the user to fill in
          imageUrl: user.photoURL || ""
        };
        await setDoc(userDocRef, newUser);
      }
  
      navigate("/profile"); // Redirect to the home page or profile
    } catch (error) {
      console.error("Error logging in with Google", error);
    }
  };
  

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
    if (newValue === 0) {
      navigate("/");
    } else if (newValue === 1) {
      navigate("/my-rides");
    } else if (newValue === 2) {
      navigate("/create-ride");
    } else if (newValue === 3) {
      navigate("/profile");
    }
  };

  const handleContinueWithoutAccount = () => {
    navigate("/");
  };

  return (
    <div style={{ backgroundColor: "#F8B400", minHeight: "100vh" }}>
      <AppBar position="fixed" sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(10px)", paddingBottom: 1, paddingTop: 0.5 }}>
        <Toolbar>
          <Typography variant="h6" sx={{ color: "black", fontFamily: "Outfit", fontWeight: "bold", flexGrow: 1 }}>
            Upsy.mk
          </Typography>
        </Toolbar>
      </AppBar>
      <Container style={{ marginTop: 64, display: 'flex', padding: 0, justifyContent: 'center' }}>
        <Hidden smDown>
        <Box sx={{ width: 200, marginRight: 0.5, position: "fixed", marginRight: 130 }}>
            <Box sx={{ textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" style={{ fontFamily: 'Outfit', fontWeight: 'bold' }}>
                Upsy.
              </Typography>
              <Typography variant="body2" style={{ fontFamily: 'Montserrat' }}>
                Каде денес?
              </Typography>
            </Box>
            <List component="nav" style={{ position: 'relative', top: 0 }}>
              <ListItem button selected={navValue === 0} onClick={() => handleNavChange(null, 0)}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Дома" />
              </ListItem>
              <ListItem button selected={navValue === 1} onClick={() => handleNavChange(null, 1)}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold', fontSize:'15px'}} primary="Мои Превози" />
              </ListItem>
              <ListItem button selected={navValue === 2} onClick={() => handleNavChange(null, 2)}>
                <ListItemIcon>
                  <PlaylistAddIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Нов Превоз" />
              </ListItem>
              <ListItem button selected={navValue === 3} onClick={() => handleNavChange(null, 3)}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Профил" />
              </ListItem>
              <ListItem button selected={navValue === 4} onClick={() => handleNavChange(null, 4)}>
                <ListItemIcon>
                  <AddToPhotosIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Пакети" />
              </ListItem>
            </List>
          </Box>
        </Hidden>
        <Container maxWidth="md" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 35 }}>
          <Paper
            sx={{
              p: 4,
              borderRadius: 3,
              border: '1px solid #ddd',
              maxWidth: 400,
              margin: '0 auto',
              boxShadow: 3,
              '@media (max-width: 600px)': { margin: 2 },
              textAlign: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }}
          >
            <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Outfit', fontWeight: 'bold' }}>
              Upsy.
            </Typography>
            <form onSubmit={handleLogin}>
              <TextField
              InputLabelProps={{
                sx: {
                 fontFamily:'Montserrat',
                  fontWeight:'bold',
                  fontSize:'14px'
                }
              }}
                label="Внеси го твојот емаил"
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
              InputLabelProps={{
                sx: {
                 fontFamily:'Montserrat',
                  fontWeight:'bold',
                  fontSize:'14px'
                }
              }}
                label="Внеси ја твојата лозинка"
                variant="outlined"
                fullWidth
                margin="normal"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button type="submit" variant="contained" fullWidth sx={{ backgroundColor: "#F8B400", color: "black", borderRadius: 2, border: '1px solid black', mb: 2, fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                Најави се
              </Button>
              <Link href="/forgot-password" variant="body2" sx={{ display: 'block', marginBottom: 2 , fontFamily:'Montserrat'}}>
                Ја заборавивте лозинката?
              </Link>
              <Typography variant="body2" align="center" sx={{ mb: 2 , fontFamily:'Montserrat', cursor: 'pointer'}} onClick={handleContinueWithoutAccount}>
                Продолжи без акаунт
              </Typography>
              <Link href="/register" variant="body2" sx={{ display: 'block', marginBottom: 2 , fontFamily:'Montserrat'}}>
                Регистрирај се веднаш!
              </Link>
              <Typography variant="body2" align="center" sx={{ mb: 2, fontFamily:'Montserrat' }}>
                Или продолжи со
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <IconButton onClick={handleGoogleLogin}>
                  <GoogleIcon />
                </IconButton>
              </Box>
            </form>
          </Paper>
        </Container>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Hidden smUp>
        <Box sx={{ width: '100%', position: 'fixed', bottom: 0, backgroundColor: '#fff' }}>
          <BottomNavigation
            value={navValue}
            onChange={handleNavChange}
            showLabels
          >
            <BottomNavigationAction label="Дома" icon={<HomeIcon />} />
            <BottomNavigationAction label="Мои Превози" icon={<DirectionsCarIcon />} />
            <BottomNavigationAction label="Нов Превоз" icon={<ListIcon />} />
            <BottomNavigationAction label="Профил" icon={<AccountCircleIcon />} />
          </BottomNavigation>
        </Box>
      </Hidden>
    </div>
  );
}

export default Login;
