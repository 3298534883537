import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  TextField,
  Button,
  Box,
  Paper,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  BottomNavigation,
  BottomNavigationAction,
  Snackbar,
  Alert,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ListIcon from "@mui/icons-material/List";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState("");
  const [navValue, setNavValue] = useState(0);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setOpenSnackbar(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
    if (newValue === 0) {
      navigate("/");
    } else if (newValue === 1) {
      navigate("/my-rides");
    } else if (newValue === 2) {
      navigate("/create-ride");
    } else if (newValue === 3) {
      navigate("/profile");
    }
  };

  return (
    <div style={{ backgroundColor: "#F8B400", minHeight: "100vh" }}>
      <AppBar position="fixed" sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(10px)", paddingBottom: 1, paddingTop: 0.5 }}>
        <Toolbar>
          <Typography variant="h6" sx={{ color: "black", fontFamily: "Outfit", fontWeight: "bold", flexGrow: 1 }}>
            Upsy.mk
          </Typography>
        </Toolbar>
      </AppBar>
      <Container style={{ marginTop: 64, display: 'flex', padding: 0, justifyContent: 'center' }}>
        <Hidden smDown>
        <Box sx={{ width: 200, marginRight: 0.5, position: "fixed", marginRight: 130 }}>
            <Box sx={{ textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" style={{ fontFamily: 'Outfit', fontWeight: 'bold' }}>
                Upsy.
              </Typography>
              <Typography variant="body2" style={{ fontFamily: 'Montserrat' }}>
                Каде денес?
              </Typography>
            </Box>
            <List component="nav" style={{ position: 'relative', top: 0 }}>
              <ListItem button selected={navValue === 0} onClick={() => handleNavChange(null, 0)}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Дома" />
              </ListItem>
              <ListItem button selected={navValue === 1} onClick={() => handleNavChange(null, 1)}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold', fontSize:'15px'}} primary="Мои Превози" />
              </ListItem>
              <ListItem button selected={navValue === 2} onClick={() => handleNavChange(null, 2)}>
                <ListItemIcon>
                  <PlaylistAddIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Нов Превоз" />
              </ListItem>
              <ListItem button selected={navValue === 3} onClick={() => handleNavChange(null, 3)}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Профил" />
              </ListItem>
              <ListItem button selected={navValue === 4} onClick={() => handleNavChange(null, 4)}>
                <ListItemIcon>
                  <AddToPhotosIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Пакети" />
              </ListItem>
            </List>
          </Box>
        </Hidden>
        <Container maxWidth="md" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 35 }}>
          <Paper sx={{ p: 3, borderRadius: 3, border: '1px solid #ddd', maxWidth: 600, margin: '0 auto', '@media (max-width: 600px)': { margin: 2 } }}>
            <Typography variant="h4" component="h1" gutterBottom fontFamily={'Montserrat'} fontWeight={'bold'}>
              Заборавена лозинка
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
              InputLabelProps={{
                sx: {
                 fontFamily:'Montserrat',
                  fontWeight:'bold',
                  fontSize:'15px'
                }
              }}
                label="Емаил"
                variant="outlined"
                fullWidth
                margin="normal"
                name="email"
                value={email}
                onChange={handleChange}
                error={!!error}
                helperText={error}
                sx={{ maxWidth: 600 }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#F8B400", color: "black", borderRadius: 10, border: '1px solid black', padding: '6px 12px', fontWeight: 'bold', fontFamily: 'Montserrat', maxWidth: 200 }}
                  onClick={() => navigate(-1)}
                >
                  Оди назад
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#F8B400", color: "black", borderRadius: 10, border: '1px solid black', padding: '6px 12px', fontWeight: 'bold', fontFamily: 'Montserrat', maxWidth: 200 }}
                >
                  Ресетирај
                </Button>
              </Box>
            </form>
          </Paper>
        </Container>
      </Container>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Емаил за ресетирање на лозинка испратен!
        </Alert>
      </Snackbar>
      <Hidden smUp>
        <Box sx={{ width: '100%', position: 'fixed', bottom: 0, backgroundColor: '#fff' }}>
          <BottomNavigation
            value={navValue}
            onChange={handleNavChange}
            showLabels
          >
            <BottomNavigationAction label="Дома" icon={<HomeIcon />} />
            <BottomNavigationAction label="Мои Превози" icon={<DirectionsCarIcon />} />
            <BottomNavigationAction label="Нов Превоз" icon={<ListIcon />} />
            <BottomNavigationAction label="Профил" icon={<AccountCircleIcon />} />
          </BottomNavigation>
        </Box>
      </Hidden>
    </div>
  );
}

export default ForgotPassword;
