import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  TextField,
  Button,
  Box,
  Paper,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  BottomNavigation,
  BottomNavigationAction,
  Link,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, getDocs, query, collection, where } from "firebase/firestore";
import { db } from "./firebaseConfig";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ListIcon from "@mui/icons-material/List";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

function Register() {
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
    name: "",
    surname: "",
    phoneNumber: "",
  });
  const [errors, setErrors] = useState({});
  const [acceptedTerms, setAcceptedTerms] = useState(false); // New state for checkbox
  const [navValue, setNavValue] = useState(3); // Set default to Profile
  const navigate = useNavigate();
  const auth = getAuth();

  const handleInputChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    let newErrors = {};

    // Validate phone number
    const { phoneNumber, password } = userDetails;
    const digitOnlyRegex = /^\d+$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,}$/; // Ensures 6 characters, 1 lowercase, 1 uppercase, and 1 number

    if (!phoneNumber || phoneNumber.length !== 9 || !phoneNumber.startsWith("07")) {
      newErrors.phoneNumber = "Телефонскиот број мора да биде 9 цифри и да започнува со '07'.";
    } else if (!digitOnlyRegex.test(phoneNumber)) {
      newErrors.phoneNumber = "Телефонскиот број мора да содржи само цифри.";
    }

    // Validate password
    if (password.length < 6) {
      newErrors.password = "Лозинката мора да биде долга најмалку 6 карактери.";
    } else if (!passwordRegex.test(password)) {
      newErrors.password = "Лозинката мора да содржи барем една мала буква, една голема буква и еден број.";
    }

    // Check if email or phone number already exists
    const emailQuery = query(collection(db, "users"), where("email", "==", userDetails.email));
    const phoneQuery = query(collection(db, "users"), where("phoneNumber", "==", phoneNumber));

    const emailSnapshot = await getDocs(emailQuery);
    const phoneSnapshot = await getDocs(phoneQuery);

    if (!emailSnapshot.empty) {
      newErrors.email = "Емаилот веќе постои.";
    }

    if (!phoneSnapshot.empty) {
      newErrors.phoneNumber = "Телефонскиот број веќе постои.";
    }

    // Validate terms and conditions acceptance
    if (!acceptedTerms) {
      newErrors.acceptedTerms = "Мора да ги прифатите Условите за користење и Политиката за приватност.";
    }

    // If there are errors, set them and stop
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, userDetails.email, userDetails.password);
      const user = userCredential.user;

      const userDoc = {
        email: userDetails.email,
        name: userDetails.name,
        surname: userDetails.surname,
        phoneNumber: userDetails.phoneNumber,
      };

      await setDoc(doc(db, "users", user.uid), userDoc);
      navigate("/");
    } catch (error) {
      console.error("Error registering new user:", error);
      setErrors({ general: error.message });
    }
  };

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
    if (newValue === 0) {
      navigate("/");
    } else if (newValue === 1) {
      navigate("/my-rides");
    } else if (newValue === 2) {
      navigate("/create-ride");
    } else if (newValue === 3) {
      navigate("/profile");
    }
  };

  return (
    <div style={{ backgroundColor: "#F8B400", minHeight: "100vh" }}>
      <AppBar position="fixed" sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(10px)", paddingBottom: 1, paddingTop: 0.5 }}>
        <Toolbar>
          <Typography variant="h6" sx={{ color: "black", fontFamily: "Outfit", fontWeight: "bold", flexGrow: 1 }}>
            Upsy.mk
          </Typography>
        </Toolbar>
      </AppBar>
      <Container style={{ marginTop: 64, display: 'flex', padding: 0, justifyContent: 'center' }}>
        <Hidden smDown>
        <Box sx={{ width: 200, marginRight: 0.5, position: "fixed", marginRight: 130 }}>
            <Box sx={{ textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" style={{ fontFamily: 'Outfit', fontWeight: 'bold' }}>
                Upsy.
              </Typography>
              <Typography variant="body2" style={{ fontFamily: 'Montserrat' }}>
                Каде денес?
              </Typography>
            </Box>
            <List component="nav" style={{ position: 'relative', top: 0 }}>
              <ListItem button selected={navValue === 0} onClick={() => handleNavChange(null, 0)}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Дома" />
              </ListItem>
              <ListItem button selected={navValue === 1} onClick={() => handleNavChange(null, 1)}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold', fontSize:'15px'}} primary="Мои Превози" />
              </ListItem>
              <ListItem button selected={navValue === 2} onClick={() => handleNavChange(null, 2)}>
                <ListItemIcon>
                  <PlaylistAddIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Нов Превоз" />
              </ListItem>
              <ListItem button selected={navValue === 3} onClick={() => handleNavChange(null, 3)}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Профил" />
              </ListItem>
              <ListItem button selected={navValue === 4} onClick={() => handleNavChange(null, 4)}>
                <ListItemIcon>
                  <AddToPhotosIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Пакети" />
              </ListItem>
            </List>
          </Box>
        </Hidden>
        <Container maxWidth="md" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 35 }}>
          <Paper
            sx={{
              p: 4,
              borderRadius: 3,
              border: '1px solid #ddd',
              maxWidth: 400,
              margin: '0 auto',
              boxShadow: 3,
              '@media (max-width: 600px)': { margin: 2 },
              textAlign: 'center',
            }}
          >
            <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              Регистрирај се!
            </Typography>
            <form onSubmit={handleRegister}>
              {/* Existing fields here */}
              <TextField
              InputLabelProps={{
                sx: {
                 fontFamily:'Montserrat',
                  fontWeight:'bold',
                  fontSize:'15px'
                }
              }}
                label="Име"
                variant="outlined"
                fullWidth
                margin="normal"
                name="name"
                value={userDetails.name}
                onChange={handleInputChange}
                error={!!errors.name}
                helperText={errors.name}
                sx={{ mb: 2 }}
              />
              <TextField
              InputLabelProps={{
                sx: {
                 fontFamily:'Montserrat',
                  fontWeight:'bold',
                  fontSize:'14px'
                }
              }}
                label="Презиме"
                variant="outlined"
                fullWidth
                margin="normal"
                name="surname"
                value={userDetails.surname}
                onChange={handleInputChange}
                error={!!errors.surname}
                helperText={errors.surname}
                sx={{ mb: 2 }}
              />
              <TextField
              InputLabelProps={{
                sx: {
                 fontFamily:'Montserrat',
                  fontWeight:'bold',
                  fontSize:'15px'
                }
              }}
                label="Емаил"
                variant="outlined"
                fullWidth
                margin="normal"
                name="email"
                value={userDetails.email}
                onChange={handleInputChange}
                error={!!errors.email}
                helperText={errors.email}
                sx={{ mb: 2 }}
              />
              <TextField
              InputLabelProps={{
                sx: {
                 fontFamily:'Montserrat',
                  fontWeight:'bold',
                  fontSize:'14px'
                }
              }}
                label="Лозинка"
                variant="outlined"
                fullWidth
                margin="normal"
                type="password"
                name="password"
                value={userDetails.password}
                onChange={handleInputChange}
                error={!!errors.password}
                helperText={errors.password}
              />
              <TextField
              InputLabelProps={{
                sx: {
                 fontFamily:'Montserrat',
                  fontWeight:'bold',
                  fontSize:'15px'
                }
              }}
                label="Тел. Број"
                variant="outlined"
                fullWidth
                margin="normal"
                name="phoneNumber"
                value={userDetails.phoneNumber}
                onChange={handleInputChange}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
              />

              {/* Terms and Conditions */}
              <FormControlLabel
                control={<Checkbox checked={acceptedTerms} onChange={(e) => setAcceptedTerms(e.target.checked)} />}
                label={
                  <Typography variant="body2" sx={{fontFamily:'Montserrat'}}>
                    Се согласувам дека сум над 18 години и со{" "}
                    <Link href="/terms-and-conditions" target="_blank">Правила и Услови</Link> и{" "}
                    <Link href="/privacy-policy" target="_blank">Политика на Приватност</Link>.
                  </Typography>
                }
              />
              {errors.acceptedTerms && (
                <FormHelperText error>{errors.acceptedTerms}</FormHelperText>
              )}

              <Button type="submit" variant="contained" fullWidth sx={{ backgroundColor: "#F8B400", color: "black", borderRadius: 2,border: '1px solid black', fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                Регистрирај се
              </Button>
              {errors.general && (
                <Typography variant="body2" color="error" style={{ marginTop: 10, textAlign: "center" }}>
                  {errors.general}
                </Typography>
              )}
              <Typography variant="body2" align="center" style={{ marginTop: 20, fontFamily:'Montserrat' }}>
                Веќе имаш акаунт? <Link href="/login">Најави се овде</Link>
              </Typography>
            </form>
          </Paper>
        </Container>
      </Container>
      <Hidden smUp>
        <Box sx={{ width: '100%', position: 'fixed', bottom: 0, backgroundColor: '#fff' }}>
          <BottomNavigation
            value={navValue}
            onChange={handleNavChange}
            showLabels
          >
            <BottomNavigationAction label="Дома" icon={<HomeIcon />} />
            <BottomNavigationAction label="Мои Превози" icon={<DirectionsCarIcon />} />
            <BottomNavigationAction label="Нов Превоз" icon={<ListIcon />} />
            <BottomNavigationAction label="Профил" icon={<AccountCircleIcon />} />
          </BottomNavigation>
        </Box>
      </Hidden>
    </div>
  );
}

export default Register;
