import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  AppBar,
  Toolbar,
  Button,
  Typography,
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Link,
  IconButton,
  Snackbar,
  Alert
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc, collection, query, where, getDocs } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "./firebaseConfig";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import logo from './logo.png'; 
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import footerImage from './footer.png';
import MenuIcon from "@mui/icons-material/Menu";

function Profile() {
  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState({});
  const [selectedFile, setSelectedFile] = useState(null); // Reintroduce selectedFile for profile picture
  const [uploading, setUploading] = useState(false);
  const [navValue, setNavValue] = useState(3); 
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const fetchProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          setProfile(userDoc.data());
          setEditedProfile(userDoc.data());
        } else {
          const newUser = {
            name: user.displayName || "",
            surname: "", 
            email: user.email || "",
            phoneNumber: "", 
            imageUrl: user.photoURL || ""
          };
          await setDoc(userDocRef, newUser);
          setProfile(newUser);
          setEditedProfile(newUser);
        }
      } else {
        navigate("/login");
      }
    };

    fetchProfile();
  }, [auth, navigate]);

  const handleLogout = async () => {
    await signOut(auth);
    navigate("/login");
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    setEditedProfile({ ...editedProfile, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]); // Capture the selected file for the profile picture
  };

  const validatePhoneNumber = async () => {
    const { phoneNumber } = editedProfile;

    const digitOnlyRegex = /^\d+$/;

    if (!phoneNumber || phoneNumber.length !== 9 || !phoneNumber.startsWith("07")) {
      setError("Телефонскиот број мора да биде 9 цифри и да започнува со '07'.");
      setSnackbarOpen(true);
      return false;
    }

    if (!digitOnlyRegex.test(phoneNumber)) {
      setError("Телефонскиот број мора да содржи само цифри.");
      setSnackbarOpen(true);
      return false;
    }

    const q = query(collection(db, "users"), where("phoneNumber", "==", phoneNumber));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty && querySnapshot.docs[0].id !== auth.currentUser.uid) {
      setError("Телефонскиот број веќе е зафатен.");
      setSnackbarOpen(true);
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    if (!await validatePhoneNumber()) return;

    const user = auth.currentUser;
    if (user) {
      if (selectedFile) {
        setUploading(true);
        const storageRef = ref(storage, `profile_pictures/${user.uid}`);
        await uploadBytes(storageRef, selectedFile);
        const photoURL = await getDownloadURL(storageRef);
        editedProfile.imageUrl = photoURL;
        setUploading(false);
      }

      const updatedProfile = {
        name: editedProfile.name || profile.name,
        surname: editedProfile.surname || profile.surname,
        phoneNumber: editedProfile.phoneNumber || profile.phoneNumber,
      };

      if (editedProfile.imageUrl) {
        updatedProfile.imageUrl = editedProfile.imageUrl; // Update imageUrl only if new picture was uploaded
      }

      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, updatedProfile);

      setProfile({
        ...profile,
        ...updatedProfile,
      });

      setIsEditing(false);
    }
  };

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
    if (newValue === 0) {
      navigate("/");
    } else if (newValue === 1) {
      navigate("/my-rides");
    } else if (newValue === 2) {
      navigate("/create-ride");
    } else if (newValue === 3) {
      navigate("/profile");
    } else if (newValue === 4) {
      navigate("/packets-selection");
    }
  };

  if (!profile) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Avatar src={logo} alt="Loading" style={{ width: 100, height: 100 }} />
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: "#F8B400", minHeight: "100vh", display: 'flex', flexDirection: 'column' }}>
      <AppBar position="fixed" sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(10px)", paddingBottom: 1, paddingTop: 0.5 }}>
        <Toolbar>
          <Hidden smUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
              sx={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h6" sx={{ color: "black", fontFamily: "Outfit", fontWeight: "bold", flexGrow: 1 }}>
            Upsy.mk
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" sx={{ backdropFilter:"blur(10px)" }} open={drawerOpen} onClose={() => setDrawerOpen(false)} >
        <List>
          <ListItem button onClick={() => { navigate("/about-us"); setDrawerOpen(false); }}>
            <ListItemText primary="За Нас" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/terms-and-conditions"); setDrawerOpen(false); }}>
            <ListItemText primary="Правила и Услови" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/privacy-policy"); setDrawerOpen(false); }}>
            <ListItemText primary="Политика на Приватност" />
          </ListItem>
        </List>
      </Drawer>
      <Container style={{ marginTop: 64, display: 'flex', padding: 0, justifyContent: 'center' }}>
        <Hidden smDown>
        <Box sx={{ width: 200, marginRight: 0.5, position: "fixed", marginRight: 130 }}>
            <Box sx={{ textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" style={{ fontFamily: 'Outfit', fontWeight: 'bold' }}>
                Upsy.
              </Typography>
              <Typography variant="body2" style={{ fontFamily: 'Montserrat' }}>
                Каде денес?
              </Typography>
            </Box>
            <List component="nav" style={{ position: 'relative', top: 0 }}>
              <ListItem button selected={navValue === 0} onClick={() => handleNavChange(null, 0)}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Дома" />
              </ListItem>
              <ListItem button selected={navValue === 1} onClick={() => handleNavChange(null, 1)}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold', fontSize:'15px'}} primary="Мои Превози" />
              </ListItem>
              <ListItem button selected={navValue === 2} onClick={() => handleNavChange(null, 2)}>
                <ListItemIcon>
                  <PlaylistAddIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Нов Превоз" />
              </ListItem>
              <ListItem button selected={navValue === 3} onClick={() => handleNavChange(null, 3)}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Профил" />
              </ListItem>
              <ListItem button selected={navValue === 4} onClick={() => handleNavChange(null, 4)}>
                <ListItemIcon>
                  <AddToPhotosIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Пакети" />
              </ListItem>
            </List>
          </Box>
        </Hidden>
        <Container maxWidth="md" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 35, paddingBottom: 50 }}>
          <Paper sx={{ p: 3, borderRadius: 3, border: '1px solid #ddd', maxWidth: 600, margin: '0 auto', '@media (max-width: 600px)': { margin: 2 } }}>
            <Typography variant="h4" component="h1" gutterBottom style={{ color: "black", textAlign: 'center' }} sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              Профил
            </Typography>
            <div style={{ textAlign: "center", marginBottom: 20 }}>
              <Avatar
                alt={profile.name || "Профил"}
                src={profile.imageUrl || logo} // Use logo.png as fallback if no profile picture
                style={{ width: 100, height: 100, margin: "0 auto", border: "3px solid #F8B400" }}
              />
              {isEditing && (
                <div>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span" style={{ marginTop: 10, fontFamily:'Montserrat', fontWeight:'bold', backgroundColor:'green' }}>
                      Промени профилна слика
                    </Button>
                  </label>
                </div>
              )}
            </div>
            <TextField
            InputLabelProps={{
              sx: {
               fontFamily:'Montserrat',
                fontWeight:'bold',
                fontSize:'15px'
              }
            }}
              label="Име"
              variant="outlined"
              fullWidth
              margin="normal"
              name="name"
              value={isEditing ? editedProfile.name || "" : profile.name || ""}
              onChange={handleInputChange}
              InputProps={{
                readOnly: !isEditing,
              }}
              sx={{ maxWidth: 600 }}
            />
            <TextField
            InputLabelProps={{
              sx: {
               fontFamily:'Montserrat',
                fontWeight:'bold',
                fontSize:'14px'
              }
            }}
              label="Презиме"
              variant="outlined"
              fullWidth
              margin="normal"
              name="surname"
              value={isEditing ? editedProfile.surname || "" : profile.surname || ""}
              onChange={handleInputChange}
              InputProps={{
                readOnly: !isEditing,
              }}
              sx={{ maxWidth: 600 }}
            />
            <TextField
            InputLabelProps={{
              sx: {
               fontFamily:'Montserrat',
                fontWeight:'bold',
                fontSize:'15px'
              }
            }}
              label="Емаил"
              variant="outlined"
              fullWidth
              margin="normal"
              name="email"
              value={profile.email || ""}
              InputProps={{
                readOnly: true,
              }}
              sx={{ maxWidth: 600 }}
            />
            <TextField
            InputLabelProps={{
              sx: {
               fontFamily:'Montserrat',
                fontWeight:'bold',
                fontSize:'15px'
              }
            }}
              label="Телефон"
              variant="outlined"
              fullWidth
              margin="normal"
              name="phoneNumber"
              value={editedProfile.phoneNumber || profile.phoneNumber || ""}
              onChange={handleInputChange}
              InputProps={{
                readOnly: profile.phoneNumber !== "", 
              }}
              sx={{ maxWidth: 600 }}
              helperText={profile.phoneNumber === "" ? "Внеси телефонски број (9 цифри, започнувајќи со '07')" : ""}
              error={Boolean(error)}
            />
            <Typography variant="body2" sx={{ fontFamily: 'Montserrat', color: 'darksilver', marginBottom: 2 }}>
              *Телефонскиот број и емаилот може само админ да ги смене.
            </Typography>
            {isEditing ? (
              <Button
                variant="contained"
                fullWidth
                onClick={handleSave}
                style={{ fontFamily:'Montserrat', fontWeight:'bold' ,backgroundColor: "#F8B400", color: "black", border: "1px solid black" }}
                disabled={uploading}
                sx={{ maxWidth: 600 }}
              >
                {uploading ? "Зачувување..." : "Зачувај"}
              </Button>
            ) : (
              <Button
                variant="contained"
                fullWidth
                onClick={handleEditToggle}
                style={{ backgroundColor: "#F8B400", color: "#fff", border: "1px solid black"}}
                sx={{ maxWidth: 600 }}
              >
                <Typography fontFamily={'Montserrat'} fontWeight={'bold'} color={'black'}>Уреди</Typography>
              </Button>
            )}
            <Button variant="contained" fullWidth onClick={handleLogout} style={{ marginTop: 10, backgroundColor: "red", border:" 1px solid black" }}>
              <Typography fontFamily={'Montserrat'} fontWeight={'bold'}>Одјави се</Typography>
            </Button>
          </Paper>
        </Container>
      </Container>
      <Hidden smUp>
        <Box sx={{ width: '100%', position: 'fixed', bottom: 0, backgroundColor: '#fff' }}>
          <BottomNavigation
            value={navValue}
            onChange={handleNavChange}
            showLabels
          >
            <BottomNavigationAction label="Дома" icon={<HomeIcon />} />
            <BottomNavigationAction label="Мои Превози" icon={<DirectionsCarIcon />} />
            <BottomNavigationAction label="Нов Превоз" icon={<PlaylistAddIcon />} />
            <BottomNavigationAction label="Профил" icon={<AccountCircleIcon />} />
            <BottomNavigationAction label="Пакети" icon={<AddToPhotosIcon />} />
          </BottomNavigation>
        </Box>
      </Hidden>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Hidden smDown>
        <Box sx={{ width: '100%', mt: 5 }}>
          <img src={footerImage} alt="Footer" style={{ width: '100%', position: 'absolute', bottom: '0' }} />
          <Box sx={{ position: 'absolute', bottom: '20px', left: '10px', display: 'flex', gap: '20px' }}>
            <Link href="/about-us" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>За Нас</Link>
            <Link href="/terms-and-conditions" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>Правила и Услови</Link>
            <Link href="/privacy-policy" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>Политика на Приватност</Link>
          </Box>
        </Box>
      </Hidden>
    </div>
  );
}

export default Profile;
