import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainScreen from "./MainScreen";
import Login from "./Login";
import Register from "./Register";
import CreateRide from "./CreateRide";
import ForgotPassword from "./ForgotPassword";
import Profile from "./Profile";
import MyRides from "./MyRides";
import SearchRides from "./SearchRides";
import CitySelection from "./CitySelection";
import ProtectedRoute from "./ProtectedRoute";
import PacketsSelection from "./PacketsSelection";
import Checkout from "./Checkout";
import AboutUs from "./AboutUs";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* Allow MainScreen to be accessed without authentication */}
        <Route path="/" element={<MainScreen />} />
        <Route path="/create-ride" element={<ProtectedRoute><CreateRide /></ProtectedRoute>} />
        <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/my-rides" element={<ProtectedRoute><MyRides /></ProtectedRoute>} />
        <Route path="/search-rides" element={<ProtectedRoute><SearchRides /></ProtectedRoute>} />
        <Route path="/city-selection" element={<ProtectedRoute><CitySelection /></ProtectedRoute>} />
        <Route path="/packets-selection" element={<PacketsSelection />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
