import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Grid,
  Card,
  CardContent,
  Button,
  useMediaQuery,
  Drawer,
  Link,
  MenuItem,
  IconButton
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import footerImage from './footer.png';
import MenuIcon from "@mui/icons-material/Menu";


const slogans = [
  "Your journey starts here!",
  "Travel with comfort.",
  "Rides made easy.",
  "Find your ride.",
  "Safe and reliable trips."
];

const packages = [
    { title: 'Еден Превоз', description: 'Еден превоз, за корисници на кои еднократно им треба да објават превоз.', btnText: 'КУПИ' },
    { title: 'Два Превози', description: 'Два превози, за корисници на кои им треба најчесто да објават превоз во два правци.', btnText: 'КУПИ' },
    { title: 'Четири Превози', description: 'Четири превози, за корисници на кои им требаат повеќе превози за објавување.', btnText: 'КУПИ' },
    { title: 'Месечно', description: 'Месечна претплата, за редовни корисници на Upsy платформата. Со оваа опција корисникот може да поставува секој ден максимум по 4 превози.', btnText: 'КУПИ' },
  ];

function PacketsSelection() {
  const [navValue, setNavValue] = useState(4);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedSlogan = slogans[Math.floor(Math.random() * slogans.length)];

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
    if (newValue === 0) {
      navigate("/");
    } else if (newValue === 1) {
      navigate("/my-rides");
    } else if (newValue === 2) {
      navigate("/create-ride");
    } else if (newValue === 3) {
      navigate("/profile");
    } else if (newValue === 4) {
      navigate("/packets-selection");
    }
  };

  const handleBuyNow = (pkg) => {
    navigate("/checkout", { state: { selectedPackage: pkg } });
  };

  return (
    <div style={{ backgroundColor: "#F8B400", display: 'flex', flexDirection: 'column',  minHeight: "100vh", paddingBottom: isMobile ? 100 : 0 }}>
      <AppBar position="fixed" sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(10px)", paddingBottom: 1, paddingTop: 0.5 }}>
        <Toolbar>
        <Hidden smUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
              sx={{ color: "black" }} // Set icon color to black
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h6" sx={{ color: "black", fontFamily: "Outfit", fontWeight: "bold", flexGrow: 1 }}>
            Upsy.mk
          </Typography>
          <Hidden smDown>
            <Typography variant="body1" sx={{ color: "black", fontFamily: "Outfit", fontWeight: "bold" }}>
              {selectedSlogan}
            </Typography>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" sx={{backdropFilter:"blur(10px)"}} open={drawerOpen} onClose={() => setDrawerOpen(false)} >
        <List>
          <ListItem button onClick={() => { navigate("/about-us"); setDrawerOpen(false); }}>
            <ListItemText primary="За Нас" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/terms-and-conditions"); setDrawerOpen(false); }}>
            <ListItemText primary="Правила и Услови" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/privacy-policy"); setDrawerOpen(false); }}>
            <ListItemText primary="Политика на Приватност" />
          </ListItem>
        </List>
      </Drawer>
      <Container style={{ marginTop: 64, display: 'flex', padding: 0, justifyContent: 'center', backgroundColor: "#F8B400", minHeight: isMobile ? 'calc(100vh - 56px)' : '100vh' }}>
        <Hidden smDown>
        <Box sx={{ width: 200, marginRight: 0.5, position: "fixed", marginRight: 130 }}>
            <Box sx={{ textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" style={{ fontFamily: 'Outfit', fontWeight: 'bold' }}>
                Upsy.
              </Typography>
              <Typography variant="body2" style={{ fontFamily: 'Montserrat' }}>
                Каде денес?
              </Typography>
            </Box>
            <List component="nav" style={{ position: 'relative', top: 0 }}>
              <ListItem button selected={navValue === 0} onClick={() => handleNavChange(null, 0)}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Дома" />
              </ListItem>
              <ListItem button selected={navValue === 1} onClick={() => handleNavChange(null, 1)}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold', fontSize:'15px'}} primary="Мои Превози" />
              </ListItem>
              <ListItem button selected={navValue === 2} onClick={() => handleNavChange(null, 2)}>
                <ListItemIcon>
                  <PlaylistAddIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Нов Превоз" />
              </ListItem>
              <ListItem button selected={navValue === 3} onClick={() => handleNavChange(null, 3)}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Профил" />
              </ListItem>
              <ListItem button selected={navValue === 4} onClick={() => handleNavChange(null, 4)}>
                <ListItemIcon>
                  <AddToPhotosIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Пакети" />
              </ListItem>
            </List>
          </Box>
        </Hidden>
        <Container maxWidth="md" style={{ paddingLeft: isMobile ? 16 : 50, paddingRight: isMobile ? 16 : 0, paddingTop: 35, paddingBottom: isMobile ? 10 : 0 }}>
          <Typography variant="h4" align="center" gutterBottom fontFamily={'Montserrat'} fontWeight={'bold'}>
            Избери го твојот пакет
          </Typography>
          <Grid container spacing={isMobile ? 2 : 4} justifyContent="center">
            {packages.map((pkg, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    height: isMobile ? 200 : 300,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    border: '2px solid #000',
                    textAlign: 'center',
                    '&:hover': {
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      borderColor: '#000',
                    },
                  }}
                >
                  <CardContent>
                    <Typography variant={isMobile ? "h5" : "h4"} gutterBottom sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                      {pkg.title}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {pkg.description}
                    </Typography>
                  </CardContent>
                  <Button
                    variant="outlined"
                    sx={{ margin: 2, color: '#000', borderColor: '#000', '&:hover': { backgroundColor: '#000', color: '#fff' } }}
                    onClick={() => handleBuyNow(pkg)}
                  >
                    {pkg.btnText}
                  </Button>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>
      <Hidden smUp>
        <Box sx={{ width: '100%', position: 'fixed', bottom: 0, backgroundColor: '#fff' }}>
          <BottomNavigation
            value={navValue}
            onChange={handleNavChange}
            showLabels
          >
            <BottomNavigationAction label="Дома" icon={<HomeIcon />} />
            <BottomNavigationAction label="Мои Превози" icon={<DirectionsCarIcon />} />
            <BottomNavigationAction label="Нов Превоз" icon={<PlaylistAddIcon />} />
            <BottomNavigationAction label="Профил" icon={<AccountCircleIcon />} />
            <BottomNavigationAction label="Пакети" icon={<AddToPhotosIcon />} />
          </BottomNavigation>
        </Box>
      </Hidden>
      <Hidden smDown>
      <Box
  sx={{
    position: 'relative',
    width: '100%',
    height: 'auto', // Adjust height to fit content, not fixed height
    mt: 'auto', // Pushes the footer down if there is content above it
    padding: 0, // Ensure no padding
    margin: 0, // Ensure no margin
    display: 'flex', // Flexbox to help align the content
    justifyContent: 'center' // Centers content in the middle horizontally
  }}
>
  <img src={footerImage} alt="Footer" style={{ width: '100%', position: 'relative', bottom: 0 }} />
  <Box
    sx={{
      position: 'absolute',
      bottom: '20px',
      left: '10px',
      display: 'flex',
      gap: '20px',
    }}
  >
    <Link href="/about-us" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>За нас</Link>
    <Link href="/terms-and-conditions" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>Правила и Услови</Link>
    <Link href="/privacy-policy" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>Политика на Приватност</Link>
  </Box>
</Box>

      </Hidden>
    </div>
  );
}

export default PacketsSelection;
